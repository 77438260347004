.latest-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .post {
    width: 100%;
    max-width: 800px; /* Adjust width for more rectangular layout */
    background-color: #2b2f344e;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    color: white;
    display: flex;
    flex-direction: column;
  }
  
  .post-header {
    display: flex;
    align-items: center;
  }
  
  .profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .post-info {
    flex: 1;
  }
  
  .post-author-row {
    display: flex;
    align-items: center;
  }
  
  .post-author {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .post-username {
    color: #888;
    margin-right: 5px;
  }
  
  .post-time {
    color: #888;
  }
  
  .post-content {
    margin-top: 15px;
    text-align: left;
  }
  
  .post-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
  
  .post-img {
    width: calc(50% - 10px); /* Adjust size for better layout */
    height: auto;
    border-radius: 10px;
  }
  
  .post-interactions {
    display: flex;
    justify-content:space-evenly;
    margin-top: 10px;
    font-size: 14px;
    color: #ccc;
  }
  