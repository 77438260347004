/* App.css */

@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}
/* General scrollbar styling for all scrollable elements */
::-webkit-scrollbar {
  width: 8px; /* Set scrollbar width */
  height: 8px; /* Set scrollbar height for horizontal scroll */
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(43, 47, 52, 0.8); /* Darker background for track */
  border-radius: 10px; /* Rounded corners for the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(12, 12, 12, 0.8); /* Color of the scrollbar handle */
  border-radius: 10px; /* Rounded corners for the handle */
  border: 1px solid rgba(43, 47, 52, 0.8); /* Adds a border around the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(28, 28, 28, 0.8); /* Brighten handle on hover */
}

/* Handle on active (while clicking) */
::-webkit-scrollbar-thumb:active {
  background-color: rgba(107, 107, 107, 0.9); /* Slightly darken handle on active */
}

/* For Firefox */
* {
  scrollbar-width:thin; /* Thin scrollbar for Firefox */
  scrollbar-color: rgba(12, 12, 12, 0.8) rgba(43, 47, 52, 0.8); /* Colors for the scrollbar and track */
}

.HowAMI
{
  position: relative;
  background-image: url(/src/ICONS/ExlamationMark.svg);
  background-size: cover;
  opacity: 15%;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.HowAMI:hover
{
  opacity: 50%;
}
#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* Full viewport height */
  background-color: black;
  background-image: url('webbg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  /* Use environment variables for better height calculation */
  height: calc(var(--vh, 1vh) * 100);
}



/* Header styles */
header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* Center items vertically if needed */
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  font-size: 2em;
  font-weight: 200;
  position: relative;
  /* Needed for positioning child elements absolutely */

}

/* CSS for highlighting NEBHAN */
.NEBHANFOOTERHIGHLIGHT {
  color: #ffcc00;
  /* Change to desired color */
  font-weight: bold;
  /* Optional: make the text bold */
}

/* Logo positioning */
.MainWebsiteLOGO {
  position: relative;
  left: -13%;
  transform: translateX(-50%);
  background-image: url('/src/ICONS/LOGO.svg');
  background-size: cover;
  width: 50px;
  height: 50px;
}

/* Responsive adjustments */
@media (max-width: 768px) {

  /* Adjust logo positioning for smaller screens */
  .MainWebsiteLOGO {
    left: -10%;
    width: 40px;
    /* Adjust logo size */
    height: 40px;
    max-width: calc(50% - 20px);
    /* Adjust based on new logo size */
  }

}

@media (max-width: 480px) {

  /* Further adjustments for very small screens */
  .MainWebsiteLOGO {
    left: -5%;
    width: 30px;
    /* Further reduce logo size */
    height: 30px;
    max-width: calc(60% - 15px);
    /* Further adjust based on new logo size */
  }

}

header p {
  position: relative;
  right: 35px;
  font-size: 18px;
  font-weight: 100;
}

#psychoTitle {
  margin-left: 10px;
}

.App {
  text-align: center;
  color: white;
}

main {
  padding: 0px;
}

footer {
  font-size: 13px;
  padding: 10px;
}


/* Sticky Tabs Container */
.tabs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
  top: 0;
  /* Stick to the top of the viewport */
  background-color: #2b2f3445;
  border-bottom: 1px solid rgba(255, 51, 0, 0.371);
  box-shadow: 5px 5px 10px 1px #090106;
  z-index: 1;
  /* Make sure it's above other content */
  padding: 0 0px;
  backdrop-filter: blur(10px); /* Apply blur effect */
  /* Add some padding to avoid content being too close to edges */
}

.tabs-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  padding: 10px;
  margin: 0 auto;
  /* Center the tabs horizontally */
  max-width: 100%;
  /* Ensure it does not exceed the viewport */
}

.tab-button {
  display: inline-flex;
  align-items: center;
  padding: 5px 20px;
  margin: 0 5px;
  /* Reduced margin */
  background-color: #007bff00;
  font-size: 20px;
  color: rgb(165, 165, 165);
  cursor: pointer;
  min-width: 120px;
  text-align: center;
  user-select: none;
  transition: background-color 0.2s, transform 0.23s;
  position: relative;
  font-weight: 200;
  touch-action: manipulation;

  /* Improved touch handling */
  .tab-button {
    -webkit-tap-highlight-color: transparent;
    /* Removes the touch feedback color */
  }
}

.tab-button.active {
  background-color: #0d0e1091;
  border: 1px solid #484d53c9;
  border-radius: 20px;
  padding: 5px 20px;
  color: rgb(255, 255, 255);
  transform: scale(1.05);
  font-weight: 400;
  -webkit-tap-highlight-color: transparent;
  /* Removes the touch feedback color */
}

.underline {
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background: rgb(255, 51, 0);
  animation: underline-animation 0.5s forwards;
  -webkit-tap-highlight-color: transparent;
  /* Removes the touch feedback color */
}

@keyframes underline-animation {
  from {
    width: 0;
    opacity: 0;
  }

  to {
    width: 100%;
    opacity: 1;
  }
}

.tab-content {
  margin-top: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.tab-icon {
  position: relative;
  right: 10px;
  width: 25px;
  height: 25px;
}
.loading-indicator
{
  margin-top: 50px;
}

/* Additional responsiveness for mobile */
@media (max-width: 768px) {
  .tab-button {
    font-size: 16px;
    min-width: 100px;
    padding: 8px 15px;
    margin: 0 5px;
  }

  .tab-icon {
    position: relative;
    right: 10px;
    width: 20px;
    height: 20px;
  }

  header p {
    font-size: 17px;
    position: relative;
    right: 15px;
    font-weight: 100;
  }
}
