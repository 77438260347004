.whoami-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    color: white;
    text-align: center;
    padding: 20px;
}


.whoami-title {
    font-size: 4rem;
    font-weight: bold;
    background: linear-gradient(45deg, #ff6ec4, #7873f5, #78ffd6, #ff6ec4);
    background-size: 300% 300%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient-animation 10s ease infinite;
}

@keyframes gradient-animation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.whoami-description {
    margin-top: 20px;
    font-size: 1.5rem;
    max-width: 800px;
    line-height: 1.6;
}

.back-button {
    margin-top: 30px;
    padding: 15px 25px;
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    background: linear-gradient(45deg, #ff6ec4, #7873f5, #78ffd6, #ff6ec4);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px solid rgba(147, 147, 147, 0.066);
    border-radius: 10px;
    text-decoration: none;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    backdrop-filter: blur(10px); /* Apply blur effect */
}

.arrow {
    margin-left: 10px;
    font-size: 1.5rem;
}

.back-button:hover {
    transform: translateY(-5px);
    border: 1px solid rgba(147, 147, 147, 0.155);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
}
